import { StaticQuery, graphql } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { RedirectDropdown } from "../components/common/dropdown-component/makeDropdown"
import parse from "html-react-parser"

import "../styles/legal.scss"

import Navigation from "../components/navigation"
import Slide from "../components/utility/slide_content"
import Frame from "../components/common/frame"
import SidebarSmall from "../components/common/sidebar-small"

function Security({ pageContext }) {
  const { seo } = pageContext

  return (
    <>
      <StaticQuery
        query={graphql`
          query SecurityPage {
            SuperOps {
              pages(where: { title: "SEO Post" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
              }
              links(where: { section: "Security" }) {
                linkName
                slug
              }
            }
            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          <div className="blg">
            {data.SuperOps.pages.map(page => {
              const { title, navigationBlock } = page

              return (
                <>
                  <Frame seo={seo.seo}>
                    <header>
                      <Navigation
                        links={navigationBlock}
                        page={title}
                        color={seo.heroBgColor}
                      />
                    </header>

                    <section className="seo-main lgl">
                      <div
                        className="seo-hero"
                        style={{ background: seo.heroBgColor }}
                      >
                        <center>
                          <Container>
                            <Row>
                              <Col lg={4}>
                                <Slide delay="200">
                                  <div className="seo-tag">{seo.tag}</div>
                                </Slide>
                              </Col>
                              <Col lg={8}>
                                <Slide delay="200">
                                  <div className="seo-hero-heading">
                                    <h2> {seo.title} </h2>
                                  </div>
                                </Slide>
                              </Col>
                            </Row>
                          </Container>
                        </center>
                      </div>

                      <div className="seo-content">
                        <center>
                          <Container>
                            <RedirectDropdown links={data.SuperOps.links} />

                            <Row>
                              <Col lg={4}>
                                <SidebarSmall
                                  isRedirect="Yes"
                                  items={data.SuperOps.links[0]}
                                />
                              </Col>
                              <Col
                                lg={8}
                                className="legal-content position-relative"
                              >
                                {seo.policyDate && (
                                  <div className="policydate alt">
                                    <h4>{seo.policyDate}</h4>
                                  </div>
                                )}
                                <h1>{seo.subTitle}</h1>
                                <div id="seoContent">
                                  {parse(seo.content.html)}
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </center>
                      </div>
                    </section>
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default Security
