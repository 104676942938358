import React, { useState, useEffect } from "react"
import { Link } from "react-scroll"

import "../../styles/component/sidebar-small.scss"

const SidebarSmall = props => {
  //redirect
  const [param, setParam] = useState("")

  useEffect(() => {
    setParam(window.location.pathname)
  }, [])

  const linkName = props.items.linkName
  const link = props.items.slug

  return (
    <>
      {props.isRedirect ? (
        <div className="sticky-top seo-opt">
          <div className="seo-options-block">
            <div className="lgl-links p-1">
              {link.map((el, idx) => {
                return (
                  <div>
                    <a
                      className={
                        param.replace(/\//g, "") === el.replace(/\//g, "") //regex added ro remove slashes from slug and param
                          ? "res-link active"
                          : "res-link"
                      }
                      id="path"
                      href={el}
                    >
                      {linkName[idx]}
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="sticky-top seo-opt">
          <div className="seo-options-block">
            <div className="title p14">
              <p>{props.title}</p>
            </div>

            {props.items.map((t, ind) => {
              return (
                <Link
                  to={ind}
                  spy={true}
                  offset={-400}
                  smooth={true}
                  duration={150}
                  activeClass="active"
                >
                  <div className="options">
                    <div className="value p14">
                      <p>{t}</p>
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default SidebarSmall
{
  /*
    This component has 2 variants
        1. redirect
        2. in page scroll

    if isRedirect props is passed it will work as redirect bar. The default state is inpage scroll
        
*/
}
